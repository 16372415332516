import React from 'react';
import Navbar from '../components/NavBar.js';
import Footer from '../components/Footer.js';
import '../assets/styles/PolicyPages.css';

const TermsOfUse = () => {
  return (
    <div>
      <Navbar theme="dark" />
      <div className="policy-container">
        <h1 className="policy-title">Conditions Générale d’Utilisation (CGU)</h1>
        <p className="policy-subtitle">
          En utilisant idestock.fr, vous acceptez les conditions ci-dessous. Lisez-les attentivement.
        </p>

        <section className="policy-section">
          <h2>Acceptation des conditions</h2>
          <p>
            L’utilisation de notre site implique votre accord avec ces conditions. Si vous n’êtes pas d’accord, veuillez ne pas utiliser idestock.fr.
          </p>
        </section>

        <section className="policy-section">
          <h2>Propriété intellectuelle</h2>
          <p>
            Tous les contenus du site (textes, images, logos) sont la propriété exclusive d’idestock. Toute reproduction sans autorisation est interdite.
          </p>
        </section>

        <section className="policy-section">
          <h2>Utilisation du site</h2>
          <p>
            Le site doit être utilisé à des fins personnelles et légales. Vous êtes responsable des frais liés à votre accès (connexion internet, etc.).
          </p>
        </section>

        <section className="policy-section">
          <h2>Informations personnelles</h2>
          <p>
            Vous garantissez l’exactitude des informations fournies. Vous pouvez refuser leur usage à des fins marketing en nous contactant.
          </p>
        </section>

        <section className="policy-section">
          <h2>Paiement</h2>
          <p>Paiements sécurisés en euros via des méthodes protégées (SSL, 3DS).</p>
        </section>

        <section className="policy-section">
          <h2>Livraison</h2>
          <p>
            Livraison sous <strong>14 jours ouvrés</strong> maximum après la commande, selon disponibilité.
          </p>
        </section>

        <section className="policy-section">
          <h2>Droit de rétractation</h2>
          <p>
            Vous disposez de <strong>7 jours</strong> après réception pour vous rétracter. Contactez-nous à <a href="mailto:contact@idestock.fr">contact@idestock.fr</a>.
          </p>
        </section>

        <section className="policy-section">
          <h2>Garantie</h2>
          <p>
            Nos produits sont garantis conformes. En cas de défaut, nous offrons un remplacement ou un remboursement.
          </p>
        </section>

        <section className="policy-section">
          <h2>Limitation de responsabilité</h2>
          <p>
            Nous ne sommes pas responsables des dommages indirects liés à l’utilisation du site ou des produits.
          </p>
        </section>

        <section className="policy-section">
          <h2>Modification des conditions</h2>
          <p>
            Les mises à jour seront publiées ici et prendront effet immédiatement.
          </p>
        </section>

        <section className="policy-section">
          <h2>Loi applicable</h2>
          <p>
            Ces conditions sont régies par le droit français. Les litiges seront soumis aux tribunaux compétents de Paris.
          </p>
        </section>

        <section className="policy-section">
          <h2>Nos engagements</h2>
          <p><strong>Qualité :</strong> Produits fabriqués selon des normes élevées.</p>
          <p><strong>Garantie :</strong> 1 an avec réparation ou remplacement gratuit.</p>
          <p><strong>Paiement sécurisé :</strong> Transactions protégées par SSL et 3DS.</p>
          <p><strong>Livraison :</strong> Suivi en temps réel et assurance incluse.</p>
          <p><strong>Service client :</strong> Disponible de 9h à 18h, du lundi au vendredi.</p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;