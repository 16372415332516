import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/NavBar.js';
import '../assets/styles/CategoryPage.css';
import Footer from '../components/Footer.js';

const CategoryPage = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [selectedColors, setSelectedColors] = useState({});

  const fetchProducts = useCallback(async () => {
    if (!category) {
      setError("Aucune catégorie sélectionnée.");
      return;
    }

    try {
      const response = await fetch(`https://api-2zb7yje7tq-ey.a.run.app/products`);
      if (!response.ok) throw new Error("Erreur de réseau");
      const data = await response.json();

      const categoryProducts = data[category] || [];
      if (categoryProducts.length === 0) {
        setError("Aucun produit trouvé pour cette catégorie.");
      } else {
        setProducts(categoryProducts);

        // Initialiser la couleur par défaut
        const initialColors = {};
        categoryProducts.forEach(product => {
          if (product.attributes?.colors?.length > 0) {
            initialColors[product.id] = product.attributes.colors[0];
          }
        });
        setSelectedColors(initialColors);
        setError(null);
      }
    } catch (error) {
      console.error("Erreur de récupération des produits:", error);
      setError("Impossible de charger les produits. Veuillez réessayer plus tard.");
    }
  }, [category]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleCardClick = (name) => {
    const formattedName = name.replace(/\s+/g, '-');
    navigate(`/catalogue/${formattedName}`);
  };

  const handleColorChange = (productId, color) => {
    setSelectedColors(prev => ({
      ...prev,
      [productId]: color
    }));
  };

  const getColorHex = (product, color) => {
    if (!product.attributes?.colorIds) return "#ccc";
    
    const normalizedColor = Object.keys(product.attributes.colorIds).find(
      key => key.toLowerCase().trim() === color.toLowerCase().trim()
    );

    return product.attributes.colorIds[normalizedColor] || "#ccc";
  };

  const getProductImage = (product) => {
    if (!product.attributes?.colorImages) return null;
    const selectedColor = selectedColors[product.id];
    if (!selectedColor) return null;

    const normalizedColor = Object.keys(product.attributes.colorImages).find(
      key => key.toLowerCase().trim() === selectedColor.toLowerCase().trim()
    );

    return product.attributes.colorImages[normalizedColor]?.[0] || null;
  };

  return (
    <div>
      <Navbar theme="dark" />
      <div className="category-page">
        <h1 className="category-title">{category}</h1>
        <p className="category-description">
          Parcourez notre sélection complète d'iPhone et trouvez le modèle parfait pour vous.
        </p>

        {error && <p className="error-message">{error}</p>}

        <div className="product-list">
          {products.map((product) => {
            const productImage = getProductImage(product);
            const selectedColor = selectedColors[product.id];

            return (
              <div
                key={product.id}
                className="product-card"
                onClick={() => handleCardClick(product.name)}
              >
                {productImage ? (
                  <img src={productImage} alt={product.name} className="product-image" />
                ) : (
                  <div className="placeholder-image">Image non disponible</div>
                )}
                <div className="product-info">
                  <h3 className="product-name">{product.name}</h3>
                  <p className="product-price">{product.price?.price || "Prix non disponible"}€</p>
                </div>
                
                {product.attributes?.colors && (
                  <div className="color-options" onClick={(e) => e.stopPropagation()}>
                    {product.attributes.colors.map((color) => (
                      <button
                        key={color}
                        className={`color-button ${selectedColor === color ? 'selected' : ''}`}
                        onClick={() => handleColorChange(product.id, color)}
                        style={{
                          backgroundColor: getColorHex(product, color)
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CategoryPage;