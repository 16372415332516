import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/styles/ProductPage.css';
import Navbar from '../components/NavBar.js';
import Reviews from '../components/Reviews.js';
import Stars from '../assets/images/Stars.png';
import CheckList from '../assets/images/checklist.png';
import { useCart } from '../context/CartContext';
import Footer from '../components/Footer.js';
import warranty from '../assets/images/warranty.png';
import apple from '../assets/images/apple.png';
import refund from '../assets/images/refund.png';

const ProductSkeleton = () => (
  <div className="product-container skeleton">
    <div className="carousel">
      <div className="skeleton-image" style={{ width: '100%', height: '300px' }}></div>
    </div>
    <div className="product-info">
      <div className="product-header">
        <div className="skeleton-text skeleton-name" style={{ width: '60%', height: '40px' }}></div>
        <div className="skeleton-text skeleton-price" style={{ width: '40%', height: '30px' }}></div>
      </div>
      <div className="color-selection">
        <div className="skeleton-text" style={{ width: '50%', height: '20px', marginBottom: '10px' }}></div>
        <div className="color-options">
          {[1, 2, 3].map((i) => (
            <div key={i} className="skeleton-color"></div>
          ))}
        </div>
      </div>
      <div className="skeleton-text" style={{ width: '30%', height: '20px', margin: '20px 0' }}></div>
      <ul className="product-benefits">
        {[1, 2, 3, 4, 5].map((i) => (
          <li key={i}>
            <div className="skeleton-text" style={{ width: '80%', height: '20px' }}></div>
          </li>
        ))}
      </ul>
      <div className="offer-button-container">
        <div className="skeleton-text" style={{ width: '150px', height: '40px' }}></div>
        <div className="skeleton-text" style={{ width: '150px', height: '40px' }}></div>
      </div>
    </div>
  </div>
);

const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState('iphoneSeul');
  const [selectedColor, setSelectedColor] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { addToCart } = useCart();
  const [openQuestion, setOpenQuestion] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const specificIphones = [
    "iPhone 16 Pro Max",
    "iPhone 16 Pro",
    "iPhone 15 Pro Max",
    "iPhone 15 Pro",
  ];

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const productName = id.replace(/-/g, ' ');
        const response = await fetch(`https://api-2zb7yje7tq-ey.a.run.app/products/${encodeURIComponent(productName)}`);
        if (!response.ok) throw new Error("Erreur de réseau");
        const data = await response.json();
        setProduct(data);
        if (data.attributes?.colors?.length > 0) {
          setSelectedColor(data.attributes.colors[0]);
        }
        setError(null);
      } catch (error) {
        setError("Impossible de charger le produit. Veuillez réessayer plus tard.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleSelectOffer = (offer) => {
    setSelectedOffer(offer);
  };

  const faqData = [
    {
      question: 'Livraison',
      answer: "Chez Netstock, nous savons que votre temps est précieux.",
    },
    {
      question: 'Satisfait ou remboursé',
      answer:
        "Votre satisfaction est notre priorité ! Si vous n’êtes pas entièrement satisfait de votre achat, vous avez 14 jours pour nous retourner le produit.",
    },
    {
      question: 'Produit authentique',
      answer:
        "Tous les produits que nous vendons sur Netstock sont certifiés par Apple dans le cadre d'un déstockage.",
    },
  ];

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    if (product && selectedColor) {
      const images = product.attributes.colorImages[selectedColor];
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const handlePreviousImage = () => {
    if (product && selectedColor) {
      const images = product.attributes.colorImages[selectedColor];
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
  };

  const getColorHex = (color) => {
    if (!product?.attributes?.colorIds) return "#ccc";
    const normalizedColor = Object.keys(product.attributes.colorIds).find(
      (key) => key.toLowerCase().trim() === color.toLowerCase().trim()
    );
    return product.attributes.colorIds[normalizedColor] || "#ccc";
  };

  const currentImage = selectedColor
    ? product?.attributes.colorImages[selectedColor][currentImageIndex]
    : null;

  const isIphoneCategory = specificIphones.includes(product?.name);
  const hasFreeItem = !!product?.freeItem && product.freeItem.productName !== null;

  const getDefaultFreeItem = () => {
    switch (product?.name) {
      case "iPhone 16 Pro Max":
        return {
          productName: "AirPods Max",
          productId: "prod_Rx8Iqsby77xbKq",
          color: "Minuit",
          images: ["https://firebasestorage.googleapis.com/v0/b/netstock-ffda5.firebasestorage.app/o/airpods%2FairPodsMax%2Fminuit%2F1.jpg?alt=media"],
        };
      case "iPhone 16 Pro":
        return {
          productName: "AirPods Pro 2",
          productId: "prod_Rx8InQLCeT4ozd",
          color: "Blanc",
          images: ["https://firebasestorage.googleapis.com/v0/b/netstock-ffda5.firebasestorage.app/o/airpods%2FairPodsPro2%2Fblanc%2F1.jpg?alt=media"],
        };
      case "iPhone 15 Pro Max":
        return {
          productName: "AirPods 4 avec Réduction de bruit active",
          productId: "prod_Rx8H8XsKWHWWfn",
          color: "Blanc",
          images: ["https://firebasestorage.googleapis.com/v0/b/netstock-ffda5.firebasestorage.app/o/airpods%2FairPods4anc%2Fblanc%2F1.jpg?alt=media"],
        };
      case "iPhone 15 Pro":
        return {
          productName: "AirPods 4",
          productId: "prod_Rx8Hn3ZbbGoBMr",
          color: "Blanc",
          images: ["https://firebasestorage.googleapis.com/v0/b/netstock-ffda5.firebasestorage.app/o/airpods%2FairPods4%2Fblanc%2F1.jpg?alt=media"],
        };
      default:
        return {
          productName: "AirPods Pro 2",
          productId: "prod_Rx8InQLCeT4ozd",
          images: ["https://firebasestorage.googleapis.com/v0/b/netstock-ffda5.firebasestorage.app/o/airpods%2FairPodsPro2%2Fblanc%2F1.jpg?alt=media"],
        };
    }
  };

  const freeItem = hasFreeItem ? product.freeItem : getDefaultFreeItem();

  const handleAddToCart = () => {
    const iphoneItem = {
      id: product.id,
      name: product.name,
      price: product.price.price,
      quantity: 1,
      color: selectedColor,
      image: product.attributes.colorImages[selectedColor][0],
    };

    const freeItemData = {
      id: freeItem.productId,
      name: freeItem.productName,
      price: 0,
      quantity: 1,
      color: freeItem.color || 'Default',
      image: freeItem.images[0],
    };

    if (selectedOffer === 'iphoneSeul') {
      addToCart(iphoneItem);
    } else if (selectedOffer === 'iphoneWithFreeItem' && isIphoneCategory) {
      const offerItem = {
        id: `${iphoneItem.id}-${freeItemData.id}`,
        type: 'offer',
        mainProduct: iphoneItem,
        freeProduct: freeItemData,
        quantity: 1,
        totalPrice: iphoneItem.price,
      };
      addToCart(offerItem);
    }
  };

  const handleBuyNow = async () => {
    try {
      const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
      const currentItem = {
        productId: product.id,
        quantity: 1,
        price: product.price.price,
        freeItem: selectedOffer === 'iphoneWithFreeItem' && isIphoneCategory,
      };

      const cartItemsFormatted = cartItems.map((item) => {
        if (item.type === 'offer') {
          return {
            productId: item.mainProduct.id,
            quantity: item.quantity,
            price: item.totalPrice,
            freeItem: true,
          };
        } else {
          return {
            productId: item.id,
            quantity: item.quantity,
            price: item.price,
            freeItem: false,
          };
        }
      });

      const allItems = [...cartItemsFormatted, currentItem];

      const requestBody = {
        items: allItems,
        successUrl: "https://idestock.fr/success",
        cancelUrl: "https://idestock.fr/cancel",
      };

      const response = await fetch('https://api-2zb7yje7tq-ey.a.run.app/pay', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error('Erreur lors de la création de la commande');
      const data = await response.json();
      if (data.url) window.location.href = data.url;
      else throw new Error('URL de paiement non fournie par le serveur');
    } catch (error) {
      setError(error.message || "Une erreur est survenue lors de l'achat. Veuillez réessayer.");
    }
  };

  return (
    <div>
      <Navbar theme="dark" />
      <div className="product-page">
        {error && <p className="error-message">{error}</p>}
        {loading ? (
          <ProductSkeleton />
        ) : (
          <div className="product-container">
            <div className="carousel">
              {currentImage && (
                <>
                  <img src={currentImage} alt={`${product.name} - ${selectedColor}`} className="carousel-image" />
                  <button className="carousel-button prev" onClick={handlePreviousImage}>❮</button>
                  <button className="carousel-button next" onClick={handleNextImage}>❯</button>
                </>
              )}
            </div>

            <div className="product-info">
              <div className="product-header">
                <h1>{product.name}</h1>
                <div className="product-price">
                  <span className="old-price">{product.price.oldPrice}€</span>
                  <span className="new-price">{product.price.price}€</span>
                </div>
              </div>

              <div className="color-selection">
                <h3>Choisissez votre couleur</h3>
                <div className="color-options">
                  {product.attributes.colors.map((color) => (
                    <button
                      key={color}
                      className={`color-button ${selectedColor === color ? 'selected' : ''}`}
                      onClick={() => handleColorSelect(color)}
                      style={{
                        backgroundColor: getColorHex(color),
                        border: selectedColor === color ? '2px solid #000' : '1px solid #ccc',
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className="product-rating">
                <img src={Stars} alt="Stars" className="star-icon" />
                <span> (70 avis)</span>
              </div>

              <ul className="product-benefits">
                {[
                  "70% moins cher – Profitez de réductions jusqu'à 70% sur le prix du marché.",
                  "Produit authentique – Garantie d'un produit 100% officiel et certifié.",
                  "Neuf sous blister – Produit neuf, jamais ouvert, dans son emballage d'origine.",
                  "Envoi neutre et écologique – Expédition discrète avec une faible empreinte carbone.",
                  "Service client réactif – Une équipe disponible pour une assistance rapide et efficace.",
                ].map((benefit, index) => (
                  <li key={index}>
                    <img src={CheckList} alt="Check-list" className="check-icon" />
                    <span className="benefit-text">
                      <strong>{benefit.split(' – ')[0]}</strong> – {benefit.split(' – ')[1]}
                    </span>
                  </li>
                ))}
              </ul>

              {isIphoneCategory ? (
                <div className="offers">
                  <h3>Choisissez la meilleure offre</h3>
                  <div className="offer-options">
                    {["iphoneSeul", "iphoneWithFreeItem"].map((offer) => (
                      <div
                        key={offer}
                        className={`offer-box ${selectedOffer === offer ? 'selected' : ''}`}
                        onClick={() => handleSelectOffer(offer)}
                      >
                        <div className="offer-title">
                          {offer === "iphoneSeul"
                            ? "iPhone seul"
                            : `iPhone + ${freeItem.productName} offert`}
                        </div>
                        <div className="offer-price">
                          <span className="old-price">{product.price.oldPrice}€</span>
                          <span className="new-price">{product.price.price}€</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>Pas d'offres spéciales disponibles pour ce produit.</p>
              )}

              {selectedOffer && (
                <div className="offer-button-container">
                  <button className="add-to-cart" onClick={handleAddToCart}>
                    Ajouter au panier
                  </button>
                  <button className="buy-now" onClick={handleBuyNow}>
                    Acheter maintenant
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {!loading && (
        <>
          <Reviews />
          <div className="faq-section">
            <h1>Tant de raisons d’acheter vos produits chez Netstock.</h1>
            <div className="faq">
              {faqData.map((item, index) => (
                <div key={index} className={`faq-item ${openQuestion === index ? 'open' : ''}`}>
                  <div className="faq-question" onClick={() => toggleQuestion(index)}>
                    {item.question}
                    <span className={`chevron ${openQuestion === index ? 'rotate' : ''}`}>▼</span>
                  </div>
                  {openQuestion === index && (
                    <div className="faq-answer">{item.answer}</div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="features-section">
            <div className="feature">
              <img src={warranty} alt="Livraison offerte" />
              <h3>Garantie 1 an</h3>
              <p>Votre Produit est garanti 1 an.</p>
            </div>
            <div className="feature">
              <img src={apple} alt="Service client" />
              <h3>Partenaire Apple</h3>
            </div>
            <div className="feature">
              <img src={refund} alt="Livraison internationale" />
              <h3>30 jours satisfait ou remboursé</h3>
              <p>Testez pendant 30j votre produit</p>
            </div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default ProductPage;