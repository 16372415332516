import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import CategoryPage from './pages/CategoryPage';
import ProductPage from './pages/ProductPage';
import CataloguePage from './pages/CataloguePage';
import ContactPage from './pages/ContactPage';
import RefundPolicy from './pages/RefundPolicy';
import TermsOfUse from './pages/TermsOfUse';
import DeliveryPolicy from './pages/DeliveryPolicy';
import Salesterms from './pages/SalesTerms';
import CartPage from './pages/CartPage';
import { CartProvider } from './context/CartContext';
import './assets/styles/index.css';
import CancelPage from "./pages/CancelPage";
import SuccessPage from "./pages/SuccessPage";

const App = () => {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categorie/:category" element={<CategoryPage />} />
          <Route path="/catalogue" element={<CataloguePage />} />
          <Route path="/catalogue/:id" element={<ProductPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/refundpage" element={<RefundPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/salesterms" element={<Salesterms />} />
          <Route path="/deliverypolicy" element={<DeliveryPolicy />} />
          <Route path="/panier" element={<CartPage />} />
        </Routes>
      </Router>
    </CartProvider>
  );
};

export default App;
