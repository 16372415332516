import React from 'react';
import Navbar from '../components/NavBar.js';
import Footer from '../components/Footer.js';
import '../assets/styles/PolicyPages.css';

const DeliveryPolicy = () => {
  return (
    <div>
      <Navbar theme="dark" />
      <div className="policy-container">
        <h1 className="policy-title">Politique de Livraison</h1>

        <section className="policy-section">
          <h2>Délai de Traitement et Expédition</h2>
          <p>
            Toutes les commandes passées sur notre site sont traitées avec le plus grand soin afin d’assurer une mise à disposition rapide. Dès validation de votre commande, nous procédons immédiatement à la préparation et à l’expédition de votre achat. Vous recevrez ainsi votre produit sans attente inutile.
          </p>
        </section>

        <section className="policy-section">
          <h2>Mode de Réception</h2>
          <p>
            Nous privilégions un acheminement simplifié et efficace. Dès que votre commande est finalisée, vous recevrez une confirmation contenant tous les éléments nécessaires pour accéder immédiatement à votre produit. Cette méthode nous permet de garantir une réception sans retard ni incident logistique. Nos solutions garantissent une transmission immédiate et sécurisée du produit commandé, disponible sous un format consultable dès la finalisation de l'achat.
          </p>
        </section>

        <section className="policy-section">
          <h2>Authenticité et Intégrité des Produits</h2>
          <p>
            Tous nos produits sont rigoureusement sélectionnés pour assurer une qualité optimale. Nous garantissons que chaque article reçu correspond strictement aux images et descriptions présentées sur notre site. Vous pouvez donc être assuré(e) de recevoir exactement ce que vous avez commandé, sans mauvaise surprise.
          </p>
        </section>

        <section className="policy-section">
          <h2>Suivi et Confirmation</h2>
          <p>
            Une fois votre achat complété, vous recevrez une notification détaillée vous permettant de consulter votre produit en toute simplicité. Cette confirmation fait office de garantie et atteste de la bonne réception de votre commande.
          </p>
        </section>

        <section className="policy-section">
          <h2>Assistance et Service Client</h2>
          <p>
            Si vous rencontrez la moindre difficulté avec la réception de votre commande, notre service client est à votre disposition pour toute assistance. N’hésitez pas à nous contacter afin que nous puissions vous guider pour accéder pleinement à votre achat.
          </p>
        </section>

        <section className="policy-section">
          <p>
            Chez iDestok, nous mettons un point d'honneur à assurer une satisfaction maximale en garantissant un accès immédiat, fluide et sécurisé à tous nos produits.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryPolicy;