import React from 'react';
import Navbar from '../components/NavBar.js';
import Footer from '../components/Footer.js';
import '../assets/styles/PolicyPages.css';

const DeliveryPolicy = () => {
  return (
    <div>
      <Navbar theme="dark" />
      <div className="policy-container">
        <h1 className="policy-title">Politique de Livraison</h1>

        <section className="policy-section">
          <h2>Délai de Traitement et Expédition</h2>
          <p>
            Chez iDestok, chaque commande est prise en charge avec une attention particulière pour garantir une expérience client irréprochable. Dès la validation de votre paiement, notre équipe entame immédiatement le processus de préparation et d’expédition. Ce traitement rapide vise à réduire au minimum le temps d’attente, vous permettant de recevoir ou d’accéder à votre produit sans délai superflu. Selon la nature de votre achat (produit physique ou numérique), les délais peuvent varier légèrement, mais nous nous engageons à vous informer clairement à chaque étape du processus.
          </p>
        </section>

        <section className="policy-section">
          <h2>Mode de Réception</h2>
          <p>
            Nous avons conçu un système de livraison optimisé pour vous offrir une réception rapide, simple et sécurisée. Une fois votre commande finalisée, une confirmation vous sera envoyée par e-mail ou via votre compte client, incluant toutes les informations nécessaires pour accéder à votre produit. Pour les produits numériques, cela inclut un lien sécurisé ou un fichier directement consultable ; pour les articles physiques, un numéro de suivi vous sera fourni dès l’expédition. Cette approche élimine les retards inutiles et les complications logistiques, assurant une transmission fluide et immédiate. Notre priorité est de vous offrir une expérience sans accroc, quel que soit le type de produit commandé.
          </p>
        </section>

        <section className="policy-section">
          <h2>Authenticité et Intégrité des Produits</h2>
          <p>
            La qualité est au cœur de notre engagement chez iDestok. Chaque produit proposé sur notre site fait l’objet d’une sélection rigoureuse afin de répondre à des standards élevés. Nous vous garantissons que l’article ou le contenu que vous recevrez correspondra fidèlement aux descriptions, images et spécifications présentées lors de votre achat. Pour les produits numériques, nous assurons une intégrité totale des fichiers (absence de corruption ou de modification). Pour les produits physiques, nous veillons à ce que l’emballage soit adapté pour préserver leur état jusqu’à leur arrivée chez vous. Ainsi, vous pouvez commander en toute confiance, sans craindre de mauvaises surprises.
          </p>
        </section>

        <section className="policy-section">
          <h2>Suivi et Confirmation</h2>
          <p>
            Après la finalisation de votre achat, vous recevrez une notification complète comprenant tous les détails de votre commande. Pour les livraisons physiques, cela inclut un lien de suivi mis à jour en temps réel, vous permettant de connaître l’emplacement exact de votre colis à tout moment. Pour les produits numériques, cette confirmation contient les instructions d’accès ou les fichiers eux-mêmes. Ce document sert également de preuve officielle de réception et de garantie que votre commande a été traitée avec succès. Nous vous recommandons de conserver cette confirmation pour toute référence future ou en cas de besoin d’assistance.
          </p>
        </section>

        <section className="policy-section">
          <h2>Options de Livraison et Zones Desservies</h2>
          <p>
            iDestok propose différentes options de livraison adaptées à vos besoins et à votre localisation. Pour les produits physiques, nous collaborons avec des transporteurs fiables afin de couvrir un large éventail de zones géographiques. Les délais et coûts associés peuvent varier en fonction de votre adresse de livraison, et ces informations vous seront communiquées clairement au moment de passer commande. Pour les produits numériques, la livraison est instantanée et universelle, sans restriction géographique, à condition que vous disposiez d’une connexion internet stable. Si votre région présente des particularités logistiques, notre équipe vous contactera pour trouver une solution adaptée.
          </p>
        </section>

        <section className="policy-section">
          <h2>Assistance et Service Client</h2>
          <p>
            Votre satisfaction est notre priorité absolue. Si vous rencontrez un quelconque problème lors de la réception de votre commande – qu’il s’agisse d’un retard, d’une difficulté d’accès ou d’une question technique – notre service client est disponible pour vous accompagner. Vous pouvez nous joindre via e-mail, téléphone ou le formulaire de contact sur notre site, et nous nous engageons à vous répondre dans les plus brefs délais. Que vous ayez besoin d’aide pour télécharger un fichier numérique ou pour suivre un colis physique, notre équipe est là pour vous guider et résoudre toute situation, afin que vous puissiez profiter pleinement de votre achat.
          </p>
        </section>

        <section className="policy-section">
          <h2>Gestion des Incidents de Livraison</h2>
          <p>
            Bien que nous mettions tout en œuvre pour garantir une livraison sans faille, des imprévus peuvent survenir, notamment pour les envois physiques (pertes, dommages ou erreurs d’acheminement). Dans de tels cas, nous vous invitons à signaler l’incident dans un délai de 7 jours ouvrables suivant la réception ou la date prévue de livraison. Après vérification, nous vous proposerons une solution adaptée, comme le renvoi du produit ou une compensation, selon les circonstances et dans le respect de nos conditions générales. Pour les produits numériques, tout problème technique (fichier inaccessible ou corrompu) sera corrigé rapidement après votre signalement.
          </p>
        </section>

        <section className="policy-section">
          <p>
            Chez iDestok, nous nous engageons à offrir une expérience de livraison exemplaire, alliant rapidité, transparence et fiabilité. Notre objectif est de vous assurer un accès immédiat et sécurisé à vos achats, tout en maintenant un niveau de service irréprochable. Merci de choisir iDestok pour vos besoins – nous sommes honorés de vous accompagner à chaque étape.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryPolicy;