import React, { useState } from 'react';
import Stars from '../assets/images/Stars.png';
import '../assets/styles/Reviews.css';
import review1 from '../assets/images/avis-1.jpeg';
import review2 from '../assets/images/avis-2.jpeg';
import review3 from '../assets/images/avis-3.jpeg';
import review4 from '../assets/images/avis-4.jpeg';
import review5 from '../assets/images/avis-5.jpeg';
import review6 from '../assets/images/avis-6.jpeg';
import review7 from '../assets/images/avis-7.jpeg';
import review8 from '../assets/images/avis-8.jpeg';
import review9 from '../assets/images/avis-9.jpeg';
import review10 from '../assets/images/avis-10.jpeg';

export default function Reviews() {
  const allReviews = [
    { id: 1, author: 'Sacha T.', time: 'Il y a 16 minutes', title: 'Super achat !', message: 'J’ai commandé mon MacBook en ayant un peu peur d’une arnaque mais j’ai tout reçu en 5 jours, je valide.', image: review8 },
    { id: 2, author: 'Alex P.', time: 'Il y a 30 minutes', title: 'Service au top !', message: 'Service rapide et produit conforme à la description. Merci Idestock !', image: review2 },
    { id: 3, author: 'Julie L.', time: 'Il y a 1 heure', title: 'Ravie !', message: 'Très satisfaite de ma commande. Le service client est top !', image: review10 },
    { id: 4, author: 'Pierre M.', time: 'Il y a 2 heures', title: 'Parfait !', message: 'Livraison rapide et produit en parfait état, je recommande.', image: review4 },
    { id: 5, author: 'Sophie D.', time: 'Il y a 3 heures', title: 'Génial !', message: 'Les AirPods offerts avec mon iPhone sont géniaux !', image: null },
    { id: 6, author: 'Lucas R.', time: 'Il y a 4 heures', title: 'Top qualité !', message: 'Commande bien emballée, rien à redire.', image: review6 },
    { id: 7, author: 'Emma B.', time: 'Il y a 5 heures', title: 'Expérience parfaite !', message: 'Super expérience d’achat, je reviendrai.', image: review7 },
    { id: 8, author: 'Thomas G.', time: 'Il y a 6 heures', title: 'Incroyable !', message: 'Le MacBook est comme neuf, incroyable pour le prix.', image: null },
    { id: 9, author: 'Clara V.', time: 'Il y a 7 heures', title: 'Service impeccable !', message: 'Service client réactif, un vrai plus.', image: review9 },
    { id: 10, author: 'Maxime K.', time: 'Il y a 8 heures', title: 'Très satisfait !', message: 'Mon iPhone 16 est parfait, merci Idestock.', image: review3 },
    { id: 11, author: 'Laura S.', time: 'Il y a 9 heures', title: 'Sans faute !', message: 'Achat sans soucis, livraison dans les temps.', image: null },
    { id: 12, author: 'Nathan J.', time: 'Il y a 10 heures', title: 'Bluffant !', message: 'Prix imbattables, je suis bluffé.', image: review5 },
    { id: 13, author: 'Léa F.', time: 'Il y a 11 heures', title: 'Rapide et fiable !', message: 'Commande reçue rapidement, tout est conforme.', image: review1 },
    { id: 14, author: 'Hugo C.', time: 'Il y a 12 heures', title: 'À recommander !', message: 'Super site, je recommande à mes amis.', image: null },
    { id: 15, author: 'Marie E.', time: 'Il y a 13 heures', title: 'Authentique !', message: 'Les produits sont authentiques, rien à craindre.', image: null },
  ];

  const [visibleReviews, setVisibleReviews] = useState(3);

  const shuffleReviews = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const [shuffledReviews] = useState(shuffleReviews([...allReviews]));

  const handleShowMore = () => {
    if (visibleReviews === 3) {
      setVisibleReviews(10);
    } else if (visibleReviews === 10) {
      setVisibleReviews(15);
    }
  };

  return (
    <div className="reviews">
      <h2>Excellent</h2>
      <img src={Stars} alt="5 étoiles" className="stars" />
      <p className="review-count">basé sur <span>300 avis</span></p>
      <p className="trustpilot">Trustpilot</p>

      <div className="reviews-grid">
        {shuffledReviews.slice(0, visibleReviews).map((review) => (
          <div key={review.id} className={`review-card ${!review.image ? 'no-image' : ''}`}>
            <div className="review-header">
              <img src={Stars} alt="5 étoiles" className="stars-small" />
              <p className="review-time">{review.time}</p>
            </div>
            {review.image && (
              <img src={review.image} alt={`Avis de ${review.author}`} className="review-image" />
            )}
            <strong className="review-title">{review.title}</strong>
            <p className="review-message">{review.message}</p>
            <p className="review-author">{review.author}</p>
          </div>
        ))}
      </div>

      {visibleReviews < 15 && (
        <div className="see-more">
          <button onClick={handleShowMore}>Voir plus d’avis</button>
        </div>
      )}
    </div>
  );
}