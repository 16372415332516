import React, { useEffect, useState } from 'react';
import { useCart } from '../context/CartContext';
import Navbar from '../components/NavBar';  
import '../assets/styles/CartPage.css';
import Footer from '../components/Footer.js';

const CartPage = () => {
  const { cart, updateQuantity, removeFromCart, addToCart } = useCart();
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [error, setError] = useState(null);
  const [selectedColors, setSelectedColors] = useState({});

  const handleValidateOrder = async () => {
    try {
      const cartItemsFormatted = cart.map((item) => {
        if (item.type === 'offer') {
          return {
            productId: item.mainProduct.id,
            quantity: item.quantity,
            price: item.totalPrice,
            freeItem: true,
          };
        } else {
          return {
            productId: item.id,
            quantity: item.quantity,
            price: item.price,
            freeItem: false,
          };
        }
      });

      const requestBody = {
        items: cartItemsFormatted,
        successUrl: "https://idestock.fr/success",
        cancelUrl: "https://idestock.fr/cancel",
      };

      const response = await fetch('https://api-2zb7yje7tq-ey.a.run.app/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création de la commande');
      }

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('URL de paiement non fournie par le serveur');
      }
    } catch (error) {
      setError(error.message || "Une erreur est survenue lors de la validation de la commande. Veuillez réessayer.");
    }
  };

  // Calcul du prix total
  const totalPrice = cart.reduce((total, item) => {
    if (item.type === 'offer') {
      return total + item.totalPrice * item.quantity;
    }
    return total + item.price * item.quantity;
  }, 0);

  // Fonction pour récupérer la couleur hexadécimale en fonction de `colorIds`
  const getColorHex = (product, color) => {
    if (!product.attributes?.colorIds) return "#ccc";
    
    const normalizedColor = Object.keys(product.attributes.colorIds).find(
      (key) => key.toLowerCase().trim() === color.toLowerCase().trim()
    );

    return product.attributes.colorIds[normalizedColor] || "#ccc";
  };

  // Récupération des produits recommandés (3 produits aléatoires)
  useEffect(() => {
    const fetchRandomProducts = async () => {
      try {
        const response = await fetch('https://api-2zb7yje7tq-ey.a.run.app/products');
        if (!response.ok) throw new Error("Erreur de réseau");
        const data = await response.json();

        const categories = ['iphone', 'ipad', 'macbook', 'airpods'];
        const filteredProducts = [];

        categories.forEach((category) => {
          const productsInCategory = data[category];
          if (productsInCategory && productsInCategory.length > 0) {
            const randomProduct = productsInCategory[Math.floor(Math.random() * productsInCategory.length)];
            filteredProducts.push(randomProduct);
          }
        });

        const randomProducts = filteredProducts.sort(() => 0.5 - Math.random()).slice(0, 3);
        setRecommendedProducts(randomProducts);

        const initialColors = {};
        randomProducts.forEach((product) => {
          if (product.attributes?.colors?.length > 0) {
            initialColors[product.id] = product.attributes.colors[0];
          }
        });
        setSelectedColors(initialColors);
      } catch (error) {
        setError("Impossible de charger les produits recommandés. Veuillez réessayer plus tard.");
      }
    };

    fetchRandomProducts();
  }, []);

  // Calcul du sous-total et autres
  const subtotal = totalPrice.toFixed(2);
  const shipping = 0; // Livraison gratuite
  const total = (parseFloat(subtotal) + shipping).toFixed(2);

  // Gérer le changement de couleur
  const handleColorChange = (productId, color) => {
    setSelectedColors((prev) => ({
      ...prev,
      [productId]: color,
    }));
  };

  // Ajouter un produit recommandé au panier
  const handleAddToCart = (product) => {
    const selectedColor = selectedColors[product.id];
    if (!selectedColor) {
      alert("Veuillez sélectionner une couleur.");
      return;
    }
    addToCart({
      id: product.id,
      name: product.name,
      price: product.price.price,
      quantity: 1,
      color: selectedColor,
      image: product.attributes.colorImages[selectedColor][0],
    });
  };

  // Gérer l'image en fonction de la couleur
  const getProductImage = (product) => {
    const selectedColor = selectedColors[product.id];
    if (!selectedColor || !product.attributes?.colorImages) return null;

    return product.attributes.colorImages[selectedColor]?.[0] || null;
  };

  return (
    <div>
      <Navbar theme="dark" />
      <div className="cart-page">
        <h1>Votre panier</h1>

        <p>Le montant total de votre Panier est de {subtotal} €.</p>
        <p>Bénéficiez de la livraison et des retours gratuits sur toutes les commandes.</p>

        <button className="validate-order-button-top" onClick={handleValidateOrder}>Valider la commande</button>

        {cart.length === 0 ? (
          <p>Votre panier est vide.</p>
        ) : (
          <div className="cart-items">
            {cart.map((item) => (
              <div key={item.id} className="cart-item">
                {item.type === 'offer' ? (
                  // Affichage pour une offre groupée
                  <div className="offer-group">
                    {/* iPhone */}
                    <div className="offer-item">
                      <div className="cart-item-image">
                        <img src={item.mainProduct.image} alt={item.mainProduct.name} />
                      </div>
                      <div className="cart-item-info">
                        <div className="cart-item-info-left">
                          <h3>{item.mainProduct.name}</h3>
                          <p className="product-color">
                            Couleur : {item.mainProduct.color || 'Non spécifiée'}
                          </p>
                        </div>
                        <div className="cart-item-info-right">
                          <div className="cart-item-quantity">
                            <input
                              type="number"
                              value={item.quantity}
                              onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                              min="1"
                              max="5"
                            />
                          </div>
                          <div className="cart-item-price">
                            <p>{(item.mainProduct.price * item.quantity).toFixed(2)}€</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* AirPods gratuits */}
                    <div className="offer-item">
                      <div className="cart-item-image">
                        <img src={item.freeProduct.image} alt={item.freeProduct.name} />
                      </div>
                      <div className="cart-item-info">
                        <div className="cart-item-info-left">
                          <h3>{item.freeProduct.name}</h3>
                          <p className="product-color">
                            Couleur : {item.freeProduct.color || 'Non spécifiée'}
                          </p>
                        </div>
                        <div className="cart-item-info-right">
                          <div className="cart-item-quantity">
                            {/* Pas d'input ici pour éviter la redondance */}
                          </div>
                          <div className="cart-item-price">
                            <p>{(item.freeProduct.price * item.quantity).toFixed(2)}€</p>
                            <p>
                              <button onClick={() => removeFromCart(item.id)} className="remove-link">
                                Supprimer
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Affichage pour un produit simple
                  <div className="single-item">
                    <div className="cart-item-image">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="cart-item-info">
                      <div className="cart-item-info-left">
                        <h3>{item.name}</h3>
                        <p className="product-color">
                          {item.color ? `Couleur : ${item.color}` : 'Couleur non spécifiée'}
                        </p>
                      </div>
                      <div className="cart-item-info-right">
                        <div className="cart-item-quantity">
                          <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                            min="1"
                            max="5"
                          />
                        </div>
                        <div className="cart-item-price">
                          <p>{(item.price * item.quantity).toFixed(2)}€</p>
                          <p>
                            <button onClick={() => removeFromCart(item.id)} className="remove-link">
                              Supprimer
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <div className="cart-summary">
          <div className="cart-summary-item">
            <p>Sous-total</p>
            <p>{subtotal}€</p>
          </div>
          <div className="cart-summary-item">
            <p>Livraison</p>
            <p>GRATUITE</p>
          </div>
          <div className="cart-summary-item separator">
            <p>Total</p>
            <p>{total}€</p>
          </div>
        </div>

        <button className="validate-order-button" onClick={handleValidateOrder}>Valider la commande</button>

        {error && <p className="error-message">{error}</p>}

        <h2>Recommandations pour vous</h2>
        <div className="recommended-products">
          {recommendedProducts.length > 0 ? (
            recommendedProducts.map((product) => {
              const productImage = getProductImage(product);
              const selectedColor = selectedColors[product.id];

              return (
                <div key={product.id} className="recommended-product-card">
                  <img src={productImage} alt={product.name} className="recommended-product-image" />
                  <div className="recommended-product-info">
                    <h3>{product.name}</h3>
                    <p>{product.price.price}€</p>
                  </div>

                  {product.attributes?.colors && (
                    <div className="color-options">
                      {product.attributes.colors.map((color) => (
                        <button
                          key={color}
                          className={`color-button ${selectedColor === color ? 'selected' : ''}`}
                          onClick={() => handleColorChange(product.id, color)}
                          style={{
                            backgroundColor: getColorHex(product, color),
                          }}
                        />
                      ))}
                    </div>
                  )}

                  <button onClick={() => handleAddToCart(product)} className="add-to-cart-button">
                    Ajouter au panier
                  </button>
                </div>
              );
            })
          ) : (
            <p>Chargement des produits recommandés...</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CartPage;