import React, { useState } from 'react';
import Navbar from '../components/NavBar.js';
import '../assets/styles/ContactPage.css';
import Footer from '../components/Footer.js';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [showNotification, setShowNotification] = useState(false); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <div>
      <Navbar theme="dark" />
      <div className="contact-container">
        {/* Notification */}
        {showNotification && (
          <div className="notification">
            Message envoyé avec succès !
          </div>
        )}
        <h1 className="contact-title">Contactez-nous</h1>
        <p className="contact-subtitle">
          Une question sur nos produits ? Nous sommes là pour vous.
        </p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Nom"
            value={formData.name}
            onChange={handleChange}
            className="contact-input"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
            className="contact-input"
            required
          />
          <textarea
            name="message"
            placeholder="Votre message"
            value={formData.message}
            onChange={handleChange}
            className="contact-textarea"
            required
          />
          <button type="submit" className="contact-button">
            Envoyer
          </button>
        </form>

        <div className="contact-info">
          <h3>Nos coordonnées</h3>
          <p>
            E-mail : <a href="mailto:contact@idestock.fr">contact@idestock.fr</a>
          </p>
        </div>

        <div className="contact-hours">
          <h3>Horaires de réponse</h3>
          <p>Nous répondons sous 24-48h, du lundi au vendredi.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;