import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Navbar from '../components/NavBar.js';
import '../assets/styles/CataloguePage.css';
import Footer from '../components/Footer.js';

const ProductSkeleton = () => (
  <div className="product-card skeleton">
    <div className="skeleton-image"></div>
    <div className="product-info">
      <div className="skeleton-text skeleton-name"></div>
      <div className="skeleton-text skeleton-price"></div>
    </div>
    <div className="color-options">
      {[1, 2, 3].map((i) => (
        <div key={i} className="skeleton-color"></div>
      ))}
    </div>
  </div>
);

const CataloguePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [productsByCategory, setProductsByCategory] = useState({});
  const [filteredProducts, setFilteredProducts] = useState({});
  const [error, setError] = useState(null);
  const [selectedColors, setSelectedColors] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchActive, setSearchActive] = useState(location.state?.openSearch || false);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('search') || '');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://api-2zb7yje7tq-ey.a.run.app/products`);
        if (!response.ok) throw new Error("Erreur de réseau");
        const data = await response.json();

        if (!data) {
          setError("Aucun produit disponible.");
          return;
        }

        setProductsByCategory(data);

        if (searchQuery) {
          const filtered = {};
          Object.entries(data).forEach(([category, products]) => {
            const matchingProducts = products.filter(product =>
              product.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            if (matchingProducts.length > 0) {
              filtered[category] = matchingProducts;
            }
          });
          setFilteredProducts(filtered);
        } else {
          setFilteredProducts(data);
        }

        const initialColors = {};
        Object.values(data).forEach(categoryProducts => {
          categoryProducts.forEach(product => {
            if (product.attributes?.colors?.length > 0) {
              initialColors[product.id] = product.attributes.colors[0];
            }
          });
        });
        setSelectedColors(initialColors);
        setError(null);
      } catch (error) {
        console.error("Erreur de récupération des produits:", error);
        setError("Impossible de charger les produits. Veuillez réessayer plus tard.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  useEffect(() => {
    if (location.state?.openSearch) {
      setSearchActive(true);
      navigate('/catalogue', { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    navigate(`/catalogue${query ? `?search=${encodeURIComponent(query)}` : ''}`);
  };

  const handleSearchBlur = () => {
    if (!searchQuery) {
      setSearchActive(false);
    }
  };

  const handleCardClick = (name) => {
    const formattedName = name.replace(/\s+/g, '-');
    navigate(`/catalogue/${formattedName}`);
  };

  const getColorHex = (product, color) => {
    if (!product.attributes?.colorIds) return "#ccc";
    const normalizedColor = Object.keys(product.attributes.colorIds).find(
      key => key.toLowerCase().trim() === color.toLowerCase().trim()
    );
    return product.attributes.colorIds[normalizedColor] || "#ccc";
  };

  const getProductImage = (product) => {
    if (!product.attributes?.colorImages) return null;
    const selectedColor = selectedColors[product.id];
    if (!selectedColor) return null;
    const normalizedColor = Object.keys(product.attributes.colorImages).find(
      key => key.toLowerCase().trim() === selectedColor.toLowerCase().trim()
    );
    return product.attributes.colorImages[normalizedColor]?.[0] || null;
  };

  const handleColorChange = (productId, color) => {
    setSelectedColors((prev) => ({
      ...prev,
      [productId]: color,
    }));
  };

  const displayProducts = searchQuery ? filteredProducts : productsByCategory;

  return (
    <div>
      <Navbar theme="dark" />
      <div className="catalogue-page">
        <h1 className="catalogue-title">Catalogue</h1>
        <p className="catalogue-description">
          {searchQuery 
            ? `Résultats pour "${searchQuery}"`
            : "Parcourez notre catalogue complet de produits."
          }
        </p>

        {searchActive && (
          <div className="catalogue-search-container">
            <input
              type="text"
              placeholder="Rechercher un produit..."
              value={searchQuery}
              onChange={handleSearchChange}
              onBlur={handleSearchBlur}
              className="catalogue-search-input"
              autoFocus
            />
          </div>
        )}

        {error && <p className="error-message">{error}</p>}

        {loading ? (
          <div className="category-section">
            <div className="skeleton-category-name"></div>
            <div className="product-list">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <ProductSkeleton key={i} />
              ))}
            </div>
          </div>
        ) : Object.keys(displayProducts).length === 0 && searchQuery ? (
          <p className="no-results">Aucun produit ne correspond à votre recherche.</p>
        ) : (
          Object.entries(displayProducts).map(([category, products]) => (
            <div key={category} className="category-section">
              <h2 className="category-name">{category}</h2>
              <div className="product-list">
                {products.map((product) => {
                  const productImage = getProductImage(product);
                  const selectedColor = selectedColors[product.id];

                  return (
                    <div
                      key={product.id}
                      className="product-card"
                      onClick={() => handleCardClick(product.name)}
                    >
                      {productImage ? (
                        <img src={productImage} alt={product.name} className="product-image" />
                      ) : (
                        <div className="placeholder-image">Image non disponible</div>
                      )}
                      <div className="product-info">
                        <h3 className="product-name">{product.name}</h3>
                        <p className="product-price">{product.price?.price || "Prix non disponible"}€</p>
                      </div>
                      
                      {product.attributes?.colors && (
                        <div className="color-options" onClick={(e) => e.stopPropagation()}>
                          {product.attributes.colors.map((color) => (
                            <button
                              key={color}
                              className={`color-button ${selectedColor === color ? 'selected' : ''}`}
                              onClick={() => handleColorChange(product.id, color)}
                              style={{
                                backgroundColor: getColorHex(product, color),
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        )}
      </div>

      <Footer />
    </div>
  );
};

export default CataloguePage;