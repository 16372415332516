import React from 'react';
import Navbar from '../components/NavBar.js';
import Footer from '../components/Footer.js';
import '../assets/styles/PolicyPages.css';

const SalesTerms = () => {
  return (
    <div>
      <Navbar theme="dark" />
      <div className="policy-container">
        <h1 className="policy-title">Conditions Générales de Vente (CGV)</h1>

        <section className="policy-section">
          <h2>Objet et Champ d’Application</h2>
          <p>
            Les présentes Conditions Générales de Vente (CGV) définissent les termes et conditions applicables à toute transaction réalisée sur le site iDestok. Elles régissent les relations contractuelles entre vous, l’acheteur (ci-après "le Client"), et iDestok, le vendeur (ci-après "nous" ou "la Société"). En passant une commande sur notre plateforme, vous reconnaissez avoir pris connaissance de ces CGV, les avoir comprises et les accepter sans réserve. Ces conditions s’appliquent à tous les produits proposés, qu’ils soient physiques ou numériques, et prévalent sur toute autre condition ou accord préalable, sauf dérogation écrite expresse de notre part.
          </p>
        </section>

        <section className="policy-section">
          <h2>Processus de Commande et Validation</h2>
          <p>
            Pour effectuer un achat, le Client doit suivre le processus de commande en ligne disponible sur notre site. Cela inclut la sélection des produits, la vérification du panier, la fourniture des informations de paiement et la confirmation finale de la transaction. Une fois la commande validée et le paiement effectué, un contrat de vente est formé entre le Client et iDestok. Une confirmation de commande sera envoyée par e-mail au Client, récapitulant les détails de l’achat (produits commandés, montant total, date de transaction). Il est de la responsabilité du Client de vérifier l’exactitude des informations fournies (adresse e-mail, coordonnées de livraison si applicable) avant de finaliser la commande, car toute erreur pourrait affecter la bonne exécution de la livraison.
          </p>
        </section>

        <section className="policy-section">
          <h2>Politique de Non-Remboursement</h2>
          <p>
            Chez iDestok, nous appliquons une politique stricte de non-remboursement. Une fois que votre achat est confirmé et le paiement validé, aucun remboursement ne sera effectué, quelle que soit la raison invoquée, qu’il s’agisse d’un changement d’avis, d’une erreur de commande ou d’une insatisfaction quelconque. En validant votre commande, vous acceptez expressément et irrévocablement cette condition et renoncez à toute possibilité de demander un remboursement, une annulation ou un échange après la transaction. Cette règle vise à garantir la stabilité de nos opérations et à maintenir des prix compétitifs pour tous nos clients. Nous vous recommandons vivement de lire attentivement les descriptions des produits et de poser toutes vos questions à notre service client avant de finaliser votre achat, afin d’éviter tout malentendu.
          </p>
        </section>

        <section className="policy-section">
          <h2>Contestations et Chargebacks</h2>
          <p>
            Toute contestation ou réclamation concernant un paiement doit impérativement être adressée en priorité à notre service client via les canaux officiels indiqués sur notre site (e-mail, formulaire de contact ou téléphone). Nous nous engageons à examiner chaque demande avec sérieux et diligence, et à proposer, si nécessaire, des solutions adaptées dans la limite de nos politiques internes. Toute tentative de litige bancaire direct (par exemple, une rétrofacturation ou "chargeback") sans consultation préalable sera considérée comme une action injustifiée et potentiellement frauduleuse. Dans de tels cas, nous nous réservons le droit de signaler l’incident aux institutions financières compétentes, de suspendre immédiatement et définitivement votre accès à nos services, et d’entamer toutes les démarches légales nécessaires pour récupérer les fonds contestés, y compris les frais juridiques ou administratifs engagés. Notre objectif est de privilégier une résolution amiable, mais nous agirons fermement contre toute utilisation abusive des systèmes de paiement.
          </p>
        </section>

        <section className="policy-section">
          <h2>Prix et Modalités de Paiement</h2>
          <p>
            Les prix des produits affichés sur notre site sont indiqués en euros (ou dans la devise applicable selon votre localisation) et incluent toutes les taxes applicables, sauf mention contraire. Ces prix sont susceptibles d’être modifiés à tout moment sans préavis, mais le tarif appliqué sera celui en vigueur au moment de la validation de votre commande. Le paiement doit être effectué intégralement au moment de l’achat, via les moyens de paiement proposés sur notre plateforme (carte bancaire, portefeuille électronique, ou autres méthodes disponibles). Toute tentative de paiement frauduleux ou non autorisé entraînera l’annulation immédiate de la commande et pourra faire l’objet de poursuites. Une fois le paiement confirmé, vous recevrez une facture électronique détaillant votre achat, que nous vous conseillons de conserver pour vos archives.
          </p>
        </section>

        <section className="policy-section">
          <h2>Droit de Rétractation et Exceptions</h2>
          <p>
            Conformément à la législation applicable, un droit de rétractation pourrait s’appliquer dans certains cas pour les achats en ligne. Cependant, chez iDestok, ce droit est expressément exclu pour les produits numériques livrés immédiatement après l’achat (tels que fichiers téléchargeables ou contenus accessibles en ligne), dès lors que leur fourniture a commencé avec votre accord préalable. Pour les produits physiques, le droit de rétractation est également limité en raison de la nature spécifique de nos offres. En passant commande, vous reconnaissez et acceptez ces exceptions, qui sont conformes aux dispositions légales en vigueur pour les ventes à distance. Si vous avez des doutes sur l’applicabilité de ce droit à votre achat, nous vous invitons à consulter notre service client avant de finaliser votre commande.
          </p>
        </section>

        <section className="policy-section">
          <h2>Responsabilité et Garanties</h2>
          <p>
            iDestok s’engage à fournir des produits conformes aux descriptions présentées sur le site. Toutefois, notre responsabilité se limite à la mise à disposition des articles commandés, et nous ne pouvons être tenus responsables des conséquences directes ou indirectes de leur utilisation par le Client. Pour les produits physiques, une garantie légale de conformité peut s’appliquer dans les conditions prévues par la loi, mais uniquement en cas de défaut avéré au moment de la livraison. Pour les produits numériques, nous garantissons leur accessibilité immédiate, mais leur compatibilité avec vos appareils ou logiciels reste de votre ressort. Toute réclamation concernant un défaut doit être signalée dans un délai de 7 jours ouvrables suivant la réception, accompagnée de preuves (photos, captures d’écran, etc.).
          </p>
        </section>

        <section className="policy-section">
          <h2>Litiges et Droit Applicable</h2>
          <p>
            En cas de différend relatif à une vente, nous encourageons une résolution amiable via notre service client. Si aucune solution n’est trouvée, tout litige sera soumis à la juridiction compétente dans le pays d’implantation de iDestok, conformément au droit applicable dans cette juridiction. Les présentes CGV sont rédigées en français, mais en cas de traduction, la version française prévaudra en cas de divergence d’interprétation. Nous nous réservons le droit de modifier ces conditions à tout moment, les nouvelles versions s’appliquant uniquement aux commandes passées après leur mise en ligne. Nous vous recommandons de consulter régulièrement cette page pour rester informé(e) des éventuelles mises à jour.
          </p>
        </section>

        <section className="policy-section">
          <p>
            Chez iDestok, nous valorisons la transparence et la confiance dans nos relations avec nos clients. Ces Conditions Générales de Vente visent à encadrer nos engagements mutuels de manière claire et équitable. En choisissant nos produits, vous acceptez de respecter ces règles, et nous nous engageons en retour à vous offrir un service de qualité. Merci de votre fidélité et de votre compréhension.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default SalesTerms;