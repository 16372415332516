import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import '../assets/styles/NavBar.css';

import searchImage from '../assets/images/search.svg';
import shopImage from '../assets/images/shop.svg';
import searchImageDark from '../assets/images/search-black.svg';
import shopImageDark from '../assets/images/shop-black.svg';

const Navbar = ({ theme = 'light' }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const searchIcon = theme === 'dark' ? searchImageDark : searchImage;
  const shopIcon = theme === 'dark' ? shopImageDark : shopImage;

  const { cart } = useCart();
  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Ajouter ou supprimer la classe 'menu-open' sur le body
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    // Nettoyage au démontage
    return () => document.body.classList.remove('menu-open');
  }, [menuOpen]);

  const handleSearchClick = () => {
    if (location.pathname !== '/catalogue') {
      navigate('/catalogue', { state: { openSearch: true } });
    } else {
      navigate('/catalogue', { state: { openSearch: true } });
    }
  };

  const handleBrandClick = () => {
    navigate('/');
  };

  return (
    <>
      <nav className={`navbar ${theme}`}>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${theme}`} />
          <div className={`bar ${theme}`} />
          <div className={`bar ${theme}`} />
        </div>
        <div className="brand" onClick={handleBrandClick} style={{ cursor: 'pointer' }}>
          Idestock
        </div>
        <div className="links">
          <a href="/">Accueil</a>
          <a href="/catalogue">Catalogue</a>
          <a href="/contact">Contact</a>
        </div>
        <div className="icons">
          <div className="search-container">
            <img
              src={searchIcon}
              alt="Recherche"
              onClick={handleSearchClick}
              className="search-icon"
            />
          </div>
          <a href="/panier">
            <div className="cart-icon">
              <img src={shopIcon} alt="Panier" />
              {totalItems > 0 && (
                <span className={`cart-count ${theme}`}>{totalItems}</span>
              )}
            </div>
          </a>
        </div>
      </nav>

      {menuOpen && (
        <>
          <div className="overlay" onClick={toggleMenu}></div>
          <div className={`popup-menu ${theme}`}>
            <button className="close-btn" onClick={toggleMenu}>✕</button>
            <a href="/" onClick={toggleMenu}>Accueil</a>
            <a href="/catalogue" onClick={toggleMenu}>Catalogue</a>
            <a href="/contact" onClick={toggleMenu}>Contact</a>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;