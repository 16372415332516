import React from 'react';
import '../assets/styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2 className="logo">Idestock</h2>
        </div>
        <div className="footer-section">
          <h3>Lien rapide</h3>
          <ul>
            <li><a href="/">Accueil</a></li>
            <li><a href="/catalogue">Catalogue</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Informations légales</h3>
          <ul>
            <li><a href="/refundpage">Politique de remboursement</a></li>
            <li><a href="/deliverypolicy">Politique de livraison</a></li>
            <li><a href="/termsofuse">Conditions générale d'utilisation</a></li>
            <li><a href="/salesterms">Conditions générale de vente</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2025 Idestock.fr Tout droit réservés</p>
      </div>
    </footer>
  );
}

export default Footer;
