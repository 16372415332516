import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/styles/index.css'; 
import Navbar from '../components/NavBar.js';
import Footer from '../components/Footer.js';
import Reviews from '../components/Reviews.js';
import deliveryImage from '../assets/images/delivery.svg';
import iPhone16 from '../assets/images/iPhone16.png';
import iPhone16Pro from '../assets/images/iPhone16Pro.png';
import iconBattery from '../assets/images/icon-battery.png';
import iconCamera16 from '../assets/images/icon-camera-16.png';
import iconCamera16Pro from '../assets/images/icon-camera-16pro.png';
import iconProcessa18 from '../assets/images/icon-process-a18.png';
import iconProcessa18pro from '../assets/images/icon-process-a18.png';
import iPhone from '../assets/images/iPhone.png';
import iPad from '../assets/images/iPad.png';
import Macbook from '../assets/images/Macbook.png';
import AirPods from '../assets/images/AirPods.png';
import confirmationImage from '../assets/images/confirmation.png';
import shippingImage from '../assets/images/envoi.png';
import deliveryStepImage from '../assets/images/livraison.png';
import receptionImage from '../assets/images/reception.jpg';
import secure from '../assets/images/secure.png';
import international from '../assets/images/international.png';
import customer from '../assets/images/customer.png';
import delivery from '../assets/images/delivery.png';
import vitrine from '../assets/images/netstock_vitrine.png';

export default function HomePage() {
  const [bannerText, setBannerText] = useState('Livraison offerte sans minimum d’achat');
  const [openQuestion, setOpenQuestion] = useState(null);
  const [showNotification, setShowNotification] = useState(false); 
  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('Params fromSuccess:', params.get('fromSuccess')); // Pour déboguer
    if (params.get('fromSuccess') === 'true') {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setShowNotification(false);
        navigate('/', { replace: true }); // Nettoie les paramètres
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerText((prev) =>
        prev === 'Livraison offerte sans minimum d’achat'
          ? 'Airpods offert sur une sélection d\'iPhone'
          : 'Livraison offerte sans minimum d’achat'
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const specialOfferData = [
    {
      question: 'Pour l’achat d’un IPhone 15',
      answer: 'Vous recevrez une paire d’AirPods 4 d’une valeur de 19.99€ sur notre site internet.',
    },
    {
      question: 'Pour l’achat d’un IPhone 15 Pro Max',
      answer: 'Vous recevrez une paire d’AirPods Pro d’une valeur de 29.99€ sur notre site internet.',
    },
    {
      question: 'Pour l’achat d’un IPhone 16',
      answer: 'Vous recevrez une paire d’AirPods 5 d’une valeur de 39.99€ sur notre site internet.',
    },
    {
      question: 'Pour l’achat d’un IPhone 16 Pro Max',
      answer: 'Vous recevrez une paire d’AirPods Max d’une valeur de 59.99€ sur notre site internet.',
    },
  ];

  const faqData = [
    {
      question: 'Si l’article ne me convient pas ?',
      answer:
        "À l'aide de notre politique de remboursement satisfaction garantie, il vous suffit de vous connecter à votre espace compte puis de demander un bordereau d'expédition qui vous sera envoyé par mail et qui vous permettra de renvoyer le produit à condition d'avoir déjà reçu le colis. Une fois le produit envoyé, Idestock effectuera le remboursement sur le moyen de paiement utilisé lors de l'achat.",
    },
    {
      question: 'Comment suivre la livraison de mon produit ?',
      answer:
        "Lors de l'achat vous recevrez par mail sous 24h un lien vous permettant de suivre la livraison de votre produit. Vous pouvez également suivre la livraison de votre produit dans votre espace compte.",
    },
    {
      question: 'Les produits sont-ils authentiques ?',
      answer:
        "Les produits vendus par Idestock sont vérifiés par Apple et sont 100% authentiques. De plus, la société étant basée en France, il est interdit d'après l'article L335-2 de vendre de la contrefaçon en France.",
    },
    {
      question: 'Est-ce que c’est légal ?',
      answer:
        "Bien sûr, nous récupérons des stocks des derniers produits High Tech de manière totalement légale grâce à une méthode de déstockage proposée par Apple seulement dans certains pays et nous en faisons la revente en France.",
    },
  ];

  const goToCategory = (category) => {
    navigate(`/categorie/${category}`);
  };

  const goToProduct = (productId) => {
    const productMap = {
      'prod_Rx8GO7HKO0Zkyx': 'iPhone 16',
      'prod_Rx8HmhY0ekLCNA': 'iPhone 16 Pro',
      'prod_Rx8HUo10hjptle': 'iPhone 16 Pro Max',
    };
    const productName = productMap[productId];
    if (productName) {
      const formattedName = productName.replace(/\s+/g, '-');
      navigate(`/catalogue/${formattedName}`);
    } else {
      console.error(`Produit inconnu pour l'ID: ${productId}`);
    }
  };

  const [activeStep, setActiveStep] = useState('confirmation');

  const steps = [
    {
      id: 'confirmation',
      label: 'Confirmation',
      image: confirmationImage,
      text:
        "Après le paiement de votre commande, un e-mail vous est envoyé avec vos données personnelles enregistrées et le récapitulatif de votre commande (non visible ici).",
    },
    {
      id: 'envoi',
      label: 'Envoi',
      image: shippingImage,
      text:
        "Après l'envoi de votre commande un deuxième email vous est envoyé dans lequel figure toujours le récapitulatif de votre commande mais surtout le numéro de suivi de notre expéditeur Chronopost.",
    },
    {
      id: 'livraison',
      label: 'Livraison',
      image: deliveryStepImage,
      text:
        "Votre colis une fois expédié par nos équipes sera expédié et confié à notre transporteur officiel Chronopost dans les 24 heures après la confirmation de votre commande. Votre colis arrivera sous 4 à 7 jours si vous vous faîtes livrer en France métropolitaine et 5 à 10 jours si c'est dans le reste du monde.",
    },
    {
      id: 'reception',
      label: 'Réception',
      image: receptionImage,
      text:
        "Pour le moment il n'y a qu'un seul moyen de réceptionner son colis, c'est le fait de recevoir sa commande chez soi. Les agents Chronopost livrent de 8h à 22h du lundi au samedi et vous laissent votre colis dans votre boîte au lettre sauf si le colis ne respecte pas les dimensions de celle-ci. Dans ce cas là, le livreur devra vous appeler pour que vous puissiez récupérer votre colis au moment de la livraison ou ultérieurement.",
    },
  ];

  return (
    <div className="font-sans">

      <div className="banner">
        <img src={deliveryImage} alt="Livraison" />
        {bannerText}
      </div>

      <Navbar theme="light" />

      {showNotification && (
        <div className="purchase-notification">
          Merci de votre achat, nous allons prendre votre commande en charge !
        </div>
      )}

      <div className="promo"></div>

      <div className="iphone-category">
        <h1>Choisissez l’iPhone qui vous convient</h1>
        <p>Explorez la gamme en comparant les caractéristiques de chaque iPhone disponible</p>

        <div className="iphone-grid">
          <div className="iphone-card">
            <img src={iPhone16} alt="iPhone 16" />
            <h2>iPhone 16</h2>
            <p>À partir de 199,99€</p>
            <button onClick={() => goToProduct('prod_Rx8GO7HKO0Zkyx')}>En savoir plus</button>

            <div className="iphone-specs">
              <div className="spec-item">
                <img src={iconProcessa18} alt="Puce A18" />
                <p>A18 avec GPU 4 cœurs</p>
              </div>
              <div className="spec-item">
                <img src={iconCamera16} alt="Système caméra" />
                <p>Système caméra 2‑en‑1</p>
                <p>Caméra Fusion 48 Mpx</p>
                <p>Téléobjectif 2x</p>
              </div>
              <div className="spec-item">
                <img src={iconBattery} alt="Durée vidéo" />
                <p>Jusqu’à 26 heures de lecture vidéo</p>
              </div>
            </div>
          </div>

          <div className="iphone-card">
            <img src={iPhone16Pro} alt="iPhone 16 Pro" />
            <h2>iPhone 16 Pro</h2>
            <p>À partir de 219,99€</p>
            <button onClick={() => goToProduct('prod_Rx8HmhY0ekLCNA')}>En savoir plus</button>

            <div className="iphone-specs">
              <div className="spec-item">
                <img src={iconProcessa18pro} alt="Puce A18" />
                <p>A18 avec GPU 4 cœurs</p>
              </div>
              <div className="spec-item">
                <img src={iconCamera16Pro} alt="Système caméra" />
                <p>Système caméra 2‑en‑1</p>
                <p>Caméra Fusion 48 Mpx</p>
                <p>Téléobjectif 2x</p>
              </div>
              <div className="spec-item">
                <img src={iconBattery} alt="Durée vidéo" />
                <p>Jusqu’à 26 heures de lecture vidéo</p>
              </div>
            </div>
          </div>

          <div className="iphone-card">
            <img src={iPhone16Pro} alt="iPhone 16 Pro Max" />
            <h2>iPhone 16 Pro Max</h2>
            <p>À partir de 249,99€</p>
            <button onClick={() => goToProduct('prod_Rx8HUo10hjptle')}>En savoir plus</button>

            <div className="iphone-specs">
              <div className="spec-item">
                <img src={iconProcessa18pro} alt="Puce A18" />
                <p>A18 avec GPU 4 cœurs</p>
              </div>
              <div className="spec-item">
                <img src={iconCamera16Pro} alt="Système caméra" />
                <p>Système caméra 2‑en‑1</p>
                <p>Caméra Fusion 48 Mpx</p>
                <p>Téléobjectif 2x</p>
              </div>
              <div className="spec-item">
                <img src={iconBattery} alt="Durée vidéo" />
                <p>Jusqu’à 26 heures de lecture vidéo</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="more-productcategory">
        <h1>Découvrez plus de produits</h1>
        <p>Explorez notre sélection complète et trouvez le produit parfait pour vous.</p>
        <div className="iphone-family">
          <div className="family-grid">
            <div className="family-card" onClick={() => goToCategory('iphone')}>
              <h2>iPhone</h2>
              <p>Découvrez la famille iPhone 16</p>
              <div className="buttons">
                <button className="btn-primary">En savoir plus</button>
                <button className="btn-secondary">Acheter un iPhone</button>
              </div>
              <img src={iPhone} alt="iPhone" />
            </div>

            <div className="family-card" onClick={() => goToCategory('airpods')}>
              <h2>AirPods</h2>
              <p>Explorez la nouvelle génération d’AirPods</p>
              <div className="buttons">
                <button className="btn-primary">En savoir plus</button>
                <button className="btn-secondary">Acheter</button>
              </div>
              <img src={AirPods} alt="AirPods" />
            </div>

            <div className="family-card" onClick={() => goToCategory('macbook')}>
              <h2>Macbook</h2>
              <p>Laissez-vous séduire par la puissance du MacBook</p>
              <div className="buttons">
                <button className="btn-primary">En savoir plus</button>
                <button className="btn-secondary">Acheter</button>
              </div>
              <img src={Macbook} alt="Macbook" />
            </div>

            <div className="family-card" onClick={() => goToCategory('ipad')}>
              <h2>iPad</h2>
              <p>Plongez dans l’univers de l’iPad</p>
              <div className="buttons">
                <button className="btn-primary">En savoir plus</button>
                <button className="btn-secondary">Acheter</button>
              </div>
              <img src={iPad} alt="iPad" />
            </div>
          </div>
        </div>
      </div>

      <div className="special-offer">
        <h1>Offre exceptionnelle</h1>
        <p>
          Uniquement durant le ces jours-ci, profitez d’une offre exceptionnelle, en plus du déstockage.
          Pour l’achat d’un IPhone, vous seront offert une paire d’AirPods original correspondant au modèle de l’IPhone que vous achetez.
        </p>
        <div className="faq">
          {specialOfferData.map((item, index) => (
            <div key={index} className={`faq-item ${openQuestion === index ? 'open' : ''}`}>
              <div className="faq-question" onClick={() => toggleQuestion(index)}>
                {item.question}
                <span className={`chevron ${openQuestion === index ? 'rotate' : ''}`}>▼</span>
              </div>
              {openQuestion === index && (
                <div className="faq-answer">{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <Reviews />

      <div className="order-steps">
        <h2>Les étapes de votre commande</h2>
        <div className="steps-container">
          <div className="steps-line" />
          <div className="steps">
            {steps.map((step) => (
              <div
                key={step.id}
                className={`step ${activeStep === step.id ? 'active' : ''}`}
                onClick={() => setActiveStep(step.id)}
              >
                <div className="step-circle" />
                <span>{step.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="step-content">
          <img src={steps.find((step) => step.id === activeStep).image} alt="Étape" />
          <p>{steps.find((step) => step.id === activeStep).text}</p>
        </div>
      </div>

      <div className="faq-section">
        <h1>FAQ</h1>
        <p>
          Nous sommes là pour répondre à vos questions !
          Notre service client est ouvert tous les jours de 8 heures à 22 heures
          Temps de réponse moyen : 1h
        </p>
        <div className="faq">
          {faqData.map((item, index) => (
            <div key={index} className={`faq-item ${openQuestion === index ? 'open' : ''}`}>
              <div className="faq-question" onClick={() => toggleQuestion(index)}>
                {item.question}
                <span className={`chevron ${openQuestion === index ? 'rotate' : ''}`}>▼</span>
              </div>
              {openQuestion === index && (
                <div className="faq-answer">{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="features-section">
        <div className="feature">
          <img src={delivery} alt="Livraison offerte" />
          <h3>Livraison offerte</h3>
          <p>Pour toute commande de plus de 50€ d’achat</p>
        </div>
        <div className="feature">
          <img src={international} alt="Service client" />
          <h3>Service client</h3>
          <p>Nous sommes joignable 24h / 7j</p>
        </div>
        <div className="feature">
          <img src={customer} alt="Livraison internationale" />
          <h3>Livraison internationale</h3>
          <p>Nous livrons partout dans le monde en moins de 8 jours</p>
        </div>
        <div className="feature">
          <img src={secure} alt="Paiement sécurisé" />
          <h3>Paiement sécurisé</h3>
          <p>Nous utilisons une double authentification et un moyen de cryptage</p>
        </div>
      </div>

      <div className="visit-container">
        <div className="visit-image">
          <img src={vitrine} alt="Visitez-nous" />
        </div>
        <div className="visit-text">
          <h2>Rendez-nous visite !</h2>
          <p>
            L'offre Exceptionnelle est uniquement disponible en ligne sur notre plateforme web.
            N'hésitez pas à nous rendre visite dans notre magasin physique présent à Paris et qui
            ouvrira prochainement à Bordeaux, Lyon et Nantes !
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}