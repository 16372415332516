import React, { createContext, useContext, useState, useEffect } from 'react';

// Contexte du panier
const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item) => {
    // Accepter soit un tableau d'items, soit un seul item
    const itemsToAdd = Array.isArray(item) ? item : [item];

    setCart((prevCart) => {
      let updatedCart = [...prevCart];

      itemsToAdd.forEach((newItem) => {
        if (newItem.type === 'offer') {
          // Gestion des offres groupées
          const offerIndex = updatedCart.findIndex(
            (cartItem) =>
              cartItem.type === 'offer' &&
              cartItem.id === newItem.id
          );

          if (offerIndex !== -1) {
            // Si l'offre existe déjà, augmenter la quantité
            updatedCart[offerIndex].quantity += newItem.quantity;
          } else {
            // Sinon, ajouter l'offre
            updatedCart = [...updatedCart, newItem];
          }
        } else {
          // Gestion des produits simples
          const productIndex = updatedCart.findIndex(
            (cartItem) =>
              cartItem.type !== 'offer' &&
              cartItem.id === newItem.id &&
              cartItem.color === newItem.color
          );

          if (productIndex !== -1) {
            // Si le produit existe déjà, augmenter la quantité
            updatedCart[productIndex].quantity += newItem.quantity;
          } else {
            // Sinon, ajouter le produit
            updatedCart = [...updatedCart, newItem];
          }
        }
      });

      return updatedCart;
    });
  };

  const removeFromCart = (itemId) => {
    setCart(cart.filter((item) => item.id !== itemId));
  };

  const updateQuantity = (itemId, newQuantity) => {
    if (newQuantity < 1) return;

    const updatedCart = cart.map((item) =>
      item.id === itemId ? { ...item, quantity: newQuantity } : item
    );
    setCart(updatedCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateQuantity, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};